import { VCard } from 'vuetify/lib/components/VCard';

import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm$contact;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VCard, _vm._g({
    staticClass: "pa-4 d-flex flex-column",
    class: {
      selectable: _vm.selectable,
      selected: _setup.checked
    },
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, _vm.selectable ? {
    click: _setup.onClick
  } : {}), [_c('span', {
    staticClass: "text-subtitle-1 align-middle"
  }, [_vm.selectable ? _c(_setup.VCheckbox, {
    ref: "checkboxRef",
    staticClass: "mb-2",
    staticStyle: {
      "pointer-events": "none"
    },
    attrs: {
      "id": (_vm$contact = _vm.contact) === null || _vm$contact === void 0 ? void 0 : _vm$contact.id,
      "value": _setup.checked,
      "hide-details": "",
      "dense": "",
      "off-icon": "$radioOff",
      "on-icon": "$radioOn",
      "ripple": false,
      "label": _vm.$t('action.doSelect')
    },
    on: {
      "change": _setup.onChange
    }
  }) : _vm._e(), _vm._v(" "), _c('VerticalTable', {
    attrs: {
      "headers": [{
        text: _vm.$t('name'),
        value: 'name',
        dummy: _vm.dummy
      }, {
        text: _vm.$t('email'),
        value: 'email',
        dummy: _vm.dummy
      }, {
        text: _vm.$t('accountName'),
        value: 'accountName',
        dummy: _vm.dummy
      }, {
        text: _vm.$t('department'),
        value: 'department',
        half: true,
        dummy: _vm.dummy
      }, {
        text: _vm.$t('jobTitle'),
        value: 'job_title',
        half: true,
        dummy: _vm.dummy
      }, {
        text: _vm.$t('address'),
        value: 'address',
        dummy: _vm.dummy
      }],
      "item": _vm.contact
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(item) {
        return [_c('LinkText', {
          attrs: {
            "to": item.id ? "/contact/".concat(item.id) : null,
            "text": _vm.$fullName(item),
            "external": ""
          }
        })];
      }
    }, {
      key: "item.accountName",
      fn: function fn(_ref) {
        var crm_company_name = _ref.crm_company_name,
          account = _ref.account;
        return [account ? _c('LinkText', {
          attrs: {
            "to": "/accounts/".concat(account === null || account === void 0 ? void 0 : account.id),
            "text": account === null || account === void 0 ? void 0 : account.name,
            "external": ""
          }
        }) : crm_company_name ? _c('span', [_vm._v("\n          " + _vm._s(crm_company_name) + "\n        ")]) : _c('div', {
          staticClass: "text-body-2 disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('notSet')) + "\n        ")])];
      }
    }, _setup.selectableAddresses.length ? {
      key: "item.address",
      fn: function fn(item) {
        var _setup$selectableAddr;
        return [(_setup$selectableAddr = _setup.selectableAddresses) !== null && _setup$selectableAddr !== void 0 && _setup$selectableAddr.length ? _c('FloatingTooltip', {
          attrs: {
            "top": "",
            "max-width": "400px",
            "open-delay": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var _ref3, _setup$selectableAddr2, _setup$selectableAddr3;
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('div', _vm._g(_vm._b({
                staticClass: "d-flex"
              }, 'div', attrs, false), on), [_c('AddressLabel', {
                staticStyle: {
                  "pointer-events": "none"
                },
                attrs: {
                  "small": "",
                  "detail": "",
                  "rows": "",
                  "alert": false,
                  "address": (_ref3 = (_setup$selectableAddr2 = _setup.selectableAddresses.find(function (it) {
                    var _item$primary_shippin;
                    return it.id === ((_item$primary_shippin = item.primary_shipping_address) === null || _item$primary_shippin === void 0 ? void 0 : _item$primary_shippin.id);
                  })) !== null && _setup$selectableAddr2 !== void 0 ? _setup$selectableAddr2 : _setup.selectableAddresses[0]) !== null && _ref3 !== void 0 ? _ref3 : {}
                },
                scopedSlots: _vm._u([((_setup$selectableAddr3 = _setup.selectableAddresses) === null || _setup$selectableAddr3 === void 0 ? void 0 : _setup$selectableAddr3.length) > 1 ? {
                  key: "label.prepend",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "ml-1 text-body-2 secondary-text--text flex-shrink-0"
                    }, [_vm._v("\n                    " + _vm._s('+' + _vm.$t('case', [_setup.selectableAddresses.length - 1])) + "\n                  ")])];
                  },
                  proxy: true
                } : null], null, true)
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" "), _vm._l(_setup.selectableAddresses, function (address, index) {
          return _c('AddressLabel', {
            key: index,
            class: {
              'mt-1': index > 0
            },
            attrs: {
              "detail": "",
              "rows": "",
              "small": "",
              "address": address,
              "color": "white"
            }
          });
        })], 2) : _vm._e()];
      }
    } : null], null, true)
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };