import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('VerticalTable', {
    attrs: {
      "headers": _setup.headers,
      "threshold": 420
    },
    scopedSlots: _vm._u([{
      key: "header.column",
      fn: function fn() {
        return [_c(VRow, {
          attrs: {
            "no-gutters": ""
          }
        }, [_c(VCol, {
          staticClass: "text-subtitle-2",
          attrs: {
            "cols": "5"
          }
        }, [_vm._v("\n        " + _vm._s(_setup.$t('currentData')) + "\n      ")]), _vm._v(" "), _c(VCol, {
          attrs: {
            "cols": "2"
          }
        }), _vm._v(" "), _c(VCol, {
          staticClass: "text-subtitle-2",
          attrs: {
            "cols": "5"
          }
        }, [_vm._v("\n        " + _vm._s(_setup.$t('syncedData')) + "\n      ")])], 1)];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn() {
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "dummy-before": _setup.dummyBefore,
            "dummy-after": _setup.dummyAfter,
            "before": _setup.before ? _setup.$fullName(_setup.before) : null,
            "after": _setup.after ? _setup.$fullName(_setup.after) : null
          }
        })];
      },
      proxy: true
    }, {
      key: "item.email",
      fn: function fn() {
        var _setup$before, _setup$after;
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "dummy-before": _setup.dummyBefore,
            "dummy-after": _setup.dummyAfter,
            "before": (_setup$before = _setup.before) === null || _setup$before === void 0 ? void 0 : _setup$before.email,
            "after": (_setup$after = _setup.after) === null || _setup$after === void 0 ? void 0 : _setup$after.email
          }
        })];
      },
      proxy: true
    }, {
      key: "item.tel",
      fn: function fn() {
        var _setup$before2, _setup$after2;
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "dummy-before": _setup.dummyBefore,
            "dummy-after": _setup.dummyAfter,
            "before": (_setup$before2 = _setup.before) === null || _setup$before2 === void 0 ? void 0 : _setup$before2.tel,
            "after": (_setup$after2 = _setup.after) === null || _setup$after2 === void 0 ? void 0 : _setup$after2.tel
          }
        })];
      },
      proxy: true
    }, {
      key: "item.address",
      fn: function fn() {
        var _setup$before3;
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "dummy-before": _setup.dummyBefore,
            "dummy-after": _setup.dummyAfter
          },
          scopedSlots: _vm._u([(_setup$before3 = _setup.before) !== null && _setup$before3 !== void 0 && _setup$before3.shipping_addresses.length ? {
            key: "before",
            fn: function fn() {
              return _vm._l(_setup.before.shipping_addresses, function (address, index) {
                return _c('AddressLabel', {
                  key: index,
                  class: {
                    'mt-2': index > 0
                  },
                  attrs: {
                    "detail": "",
                    "rows": "",
                    "address": address
                  }
                });
              });
            },
            proxy: true
          } : null, _setup.mergedShippingAddresses.length ? {
            key: "after",
            fn: function fn() {
              return _vm._l(_setup.mergedShippingAddresses, function (address, index) {
                return _c('AddressLabel', {
                  key: index,
                  class: {
                    'mt-2': index > 0
                  },
                  attrs: {
                    "text-class": _setup.isUpdatedAddress(address) ? 'font-weight-black' : null,
                    "detail": "",
                    "rows": "",
                    "address": address
                  }
                });
              });
            },
            proxy: true
          } : null], null, true)
        })];
      },
      proxy: true
    }, {
      key: "item.accountName",
      fn: function fn() {
        var _setup$before$account, _setup$before4, _setup$before5, _setup$after$account, _setup$after3, _setup$after4;
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "dummy-before": _setup.dummyBefore,
            "dummy-after": _setup.dummyAfter
          },
          scopedSlots: _vm._u([((_setup$before$account = (_setup$before4 = _setup.before) === null || _setup$before4 === void 0 ? void 0 : _setup$before4.account) !== null && _setup$before$account !== void 0 ? _setup$before$account : (_setup$before5 = _setup.before) === null || _setup$before5 === void 0 ? void 0 : _setup$before5.crm_company_name) ? {
            key: "before",
            fn: function fn() {
              var _setup$before$account2, _setup$before$account3;
              return [_c('span', {
                class: {
                  'text-decoration-line-through': _setup.isDiffAccountName
                }
              }, [_setup.before.account ? _c('LinkText', {
                attrs: {
                  "to": "/accounts/".concat((_setup$before$account2 = _setup.before.account) === null || _setup$before$account2 === void 0 ? void 0 : _setup$before$account2.id),
                  "text": (_setup$before$account3 = _setup.before.account) === null || _setup$before$account3 === void 0 ? void 0 : _setup$before$account3.name,
                  "external": _vm.extension
                }
              }) : _setup.before.crm_company_name ? _c('span', {
                domProps: {
                  "textContent": _vm._s(_setup.before.crm_company_name)
                }
              }) : _vm._e()], 1)];
            },
            proxy: true
          } : null, ((_setup$after$account = (_setup$after3 = _setup.after) === null || _setup$after3 === void 0 ? void 0 : _setup$after3.account) !== null && _setup$after$account !== void 0 ? _setup$after$account : (_setup$after4 = _setup.after) === null || _setup$after4 === void 0 ? void 0 : _setup$after4.crm_company_name) ? {
            key: "after",
            fn: function fn() {
              var _setup$after$account2, _setup$after$account3;
              return [_c('span', {
                class: {
                  'font-weight-black': _setup.isDiffAccountName
                }
              }, [_setup.after.account ? _c('LinkText', {
                attrs: {
                  "to": "/accounts/".concat((_setup$after$account2 = _setup.after.account) === null || _setup$after$account2 === void 0 ? void 0 : _setup$after$account2.id),
                  "text": (_setup$after$account3 = _setup.after.account) === null || _setup$after$account3 === void 0 ? void 0 : _setup$after$account3.name,
                  "external": _vm.extension
                }
              }) : _setup.after.crm_company_name ? _c('span', {
                domProps: {
                  "textContent": _vm._s(_setup.after.crm_company_name)
                }
              }) : _vm._e()], 1)];
            },
            proxy: true
          } : null], null, true)
        })];
      },
      proxy: true
    }, {
      key: "item.department",
      fn: function fn() {
        var _setup$before6, _setup$after5;
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "dummy-before": _setup.dummyBefore,
            "dummy-after": _setup.dummyAfter,
            "before": (_setup$before6 = _setup.before) === null || _setup$before6 === void 0 ? void 0 : _setup$before6.department,
            "after": (_setup$after5 = _setup.after) === null || _setup$after5 === void 0 ? void 0 : _setup$after5.department
          }
        })];
      },
      proxy: true
    }, {
      key: "item.job_title",
      fn: function fn() {
        var _setup$before7, _setup$after6;
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "dummy-before": _setup.dummyBefore,
            "dummy-after": _setup.dummyAfter,
            "before": (_setup$before7 = _setup.before) === null || _setup$before7 === void 0 ? void 0 : _setup$before7.job_title,
            "after": (_setup$after6 = _setup.after) === null || _setup$after6 === void 0 ? void 0 : _setup$after6.job_title
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };