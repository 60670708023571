import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('CustomDialog', {
    attrs: {
      "max-width": "1000px",
      "title": _setup.firstSelectedOrderId ? _setup.$t('action.checkDraft') : _setup.$t('contacts.send.draft.title')
    },
    on: {
      "open": _setup.onOpen,
      "close": _setup.onClose
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn() {
        return [_c('div', {
          staticClass: "flex-equality d-flex justify-end align-center"
        }, [!_setup.firstSelectedOrderId ? _c('TextButton', {
          staticClass: "mr-4",
          attrs: {
            "value": _setup.$t('action.createWithoutDraft')
          },
          on: {
            "click": function click($event) {
              _setup.show = false;
              _vm.$nextTick(function () {
                _setup.emits('click:cencel');
              });
            }
          }
        }) : _vm._e()], 1), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-fill-primary my-2",
          attrs: {
            "type": "button",
            "disabled": !_setup.selectedOrder
          },
          on: {
            "click": function click($event) {
              _setup.show = false;
              _vm.$nextTick(function () {
                var _setup$selectedOrder$, _setup$selectedOrder;
                _setup.emits('update:selectedOrderId', (_setup$selectedOrder$ = (_setup$selectedOrder = _setup.selectedOrder) === null || _setup$selectedOrder === void 0 ? void 0 : _setup$selectedOrder.id) !== null && _setup$selectedOrder$ !== void 0 ? _setup$selectedOrder$ : null);
              });
            }
          }
        }, [_setup.firstSelectedOrderId ? [_vm._v("\n        " + _vm._s(_setup.$t('action.changeDraft')) + "\n      ")] : [_vm._v("\n        " + _vm._s(_setup.$t('action.createFromDraft')) + "\n      ")]], 2), _vm._v(" "), _c(VSpacer, {
          staticClass: "flex-equality"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _setup.show,
      callback: function callback($$v) {
        _setup.show = $$v;
      },
      expression: "show"
    }
  }, [!_setup.firstSelectedOrderId ? _c('pre', {
    staticClass: "primary-text--text text-body-1",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_setup.$t('contacts.send.draft.importDraftOrderDesc', [_setup.orders.length])))]) : _vm._e(), _vm._v(" "), _c('OutlinedTable', {
    staticClass: "mt-4",
    attrs: {
      "value": [_setup.selectedOrder],
      "headers": [{
        text: '作成者',
        value: 'created_by'
      },
      // TODO APIの対応が必要 { text: '商品名', value: 'order_line_items[0].name', multiLine: true },
      {
        text: 'タッチ名',
        value: 'touch_name',
        multiLine: true,
        empty: _setup.$t('none')
      }, {
        text: '下書き更新日',
        value: 'updated_at',
        transform: _vm.$datetime
      }, {
        text: '下書き作成日',
        value: 'created_at',
        transform: _vm.$datetime
      }, {
        text: '',
        value: 'link'
      }],
      "items": _setup.orders,
      "item-key": "id",
      "options": _setup.tableOptions,
      "server-items-length": _setup.totalItems,
      "loading": _setup.loading,
      "show-select": "",
      "single-select": "",
      "select-disable-key": function selectDisableKey(item) {
        return item.editable == false;
      },
      "select-disable-message": _setup.$t('permissions.cantEdit'),
      "select-props": {
        'off-icon': 'mdi-radiobox-blank',
        'on-icon': 'mdi-radiobox-marked'
      },
      "checkbox-color": "primary"
    },
    on: {
      "update:options": function updateOptions($event) {
        _setup.tableOptions = $event;
      },
      "input": function input($event) {
        _setup.selectedOrder = $event === null || $event === void 0 ? void 0 : $event[0];
      }
    },
    scopedSlots: _vm._u([{
      key: "item.created_by",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v("\n      " + _vm._s(_vm.$fullName(item.created_by)) + "\n    ")];
      }
    }, {
      key: "item.touch_name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.order_line_items.length > 0 ? [_vm._v("\n        " + _vm._s(item.order_line_items[0].name) + "\n      ")] : _vm._e()];
      }
    }, {
      key: "item.link",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('LinkText', {
          attrs: {
            "to": "/orders/".concat(item.id),
            "text": _setup.$fromExtension() ? _setup.$t('contacts.send.draft.openSendWOW') : _setup.$t('contacts.send.draft.openNewWindow'),
            "external": ""
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };