import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.CustomDialog, {
    attrs: {
      "max-width": "800px",
      "title": _setup.$t('extension.overwriteDetail.title')
    },
    on: {
      "open": _setup.onOpen,
      "close": _setup.onClose
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn() {
        return [_c(VSpacer), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-outline-primary mb-4",
          attrs: {
            "type": "button"
          },
          domProps: {
            "textContent": _vm._s(_setup.$t('action.back'))
          },
          on: {
            "click": function click($event) {
              _setup._show = false;
            }
          }
        }), _vm._v(" "), _c(VSpacer)];
      },
      proxy: true
    }]),
    model: {
      value: _setup._show,
      callback: function callback($$v) {
        _setup._show = $$v;
      },
      expression: "_show"
    }
  }, [_c('Loading', {
    attrs: {
      "value": _setup._loading
    }
  }), _vm._v(" "), !!_setup._contact ? [_c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('extension.overwriteDetail.desc', [_setup.crmName])) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 mt-4"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('of', [_setup.$t('contact'), 'SendWOW'])) + "\n    ")]), _vm._v(" "), _c(_setup.ConfirmContactMigrationView, {
    staticClass: "mt-2",
    attrs: {
      "extension": "",
      "before": _setup._beforeContact,
      "after": _setup._contact
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-2 mt-6 d-flex flex-wrap align-baseline"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v("\n        " + _vm._s(_setup.$t('extension.syncOptionOverwrite')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2"
  }, [_vm._v("\n        " + _vm._s(_setup.$t('extension.syncOptionOverwrite2', [_setup.crmName])) + "\n        "), _c('TextButton', {
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "value": _setup.$t('action.reload')
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:reload', _setup._contact);
      }
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-2 mt-6 d-flex flex-wrap align-baseline"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v("\n        " + _vm._s(_setup.$t('extension.syncOptionChangeSetting')) + "\n      ")]), _vm._v(" "), _c('LinkText', {
    attrs: {
      "text": _setup.$t('extension.sendwowSetting'),
      "to": "/settings/integration/".concat(_setup.crmName.toLowerCase()),
      "external": ""
    }
  })], 1)] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };