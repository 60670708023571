/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./SyncStatusConfirmDialog.vue?vue&type=template&id=28d2dda9"
import script from "./SyncStatusConfirmDialog.vue?vue&type=script&lang=js"
export * from "./SyncStatusConfirmDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomDialog: require('/code/src/components/dialog/CustomDialog.vue').default})
