import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VRow, {
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "5"
    }
  }, [_vm.dummyBefore ? _c('SkeletonLoader', {
    attrs: {
      "text": "body-2"
    }
  }) : _vm.$scopedSlots['before'] ? [_vm._t("before")] : _vm.before ? [_c('span', {
    class: {
      'text-decoration-line-through': _vm.before !== _vm.after
    }
  }, [_vm._v("\n        " + _vm._s(_vm.before) + "\n      ")])] : _c('div', {
    staticClass: "text-body-2 disabled-text--text"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('notSet')) + "\n    ")])], 2), _vm._v(" "), _c(VCol, {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "mx-4 mx-md-8 thinright"
  })]), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "5"
    }
  }, [_vm.dummyAfter ? _c('SkeletonLoader', {
    attrs: {
      "text": "body-2"
    }
  }) : _vm.$scopedSlots['after'] ? [_vm._t("after")] : _vm.after ? [_c('span', {
    class: {
      'font-weight-black': !_vm.dummyBefore && _vm.before !== _vm.after
    }
  }, [_vm._v("\n        " + _vm._s(_vm.after) + "\n      ")])] : _c('div', {
    staticClass: "text-body-2 disabled-text--text"
  }, [_c('span', [_vm._v(_vm._s(_setup.$t('notSet')))])])], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };