import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('CustomDialog', {
    attrs: {
      "max-width": "800px",
      "title": _setup.$t('confirmation')
    },
    on: {
      "open": _setup.onOpen,
      "close": _setup.onClose
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn() {
        return [_c(VSpacer), _vm._v(" "), _c('div', {
          staticClass: "d-flex flex-column mb-6"
        }, [_c('button', {
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              var _setup$callback;
              (_setup$callback = _setup.callback) === null || _setup$callback === void 0 ? void 0 : _setup$callback.call(_setup, true);
              _setup.callback = null;
              _setup._show = false;
              _vm.$nextTick(function () {
                _setup.emits('click:positive');
              });
            }
          }
        }, [_vm._v("\n        " + _vm._s(_setup.$t('extension.confirm.continue')) + "\n      ")]), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-outline-primary mt-4",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _setup._show = false;
              _vm.$nextTick(function () {
                _setup.emits('click:negative');
              });
            }
          }
        }, [_vm._v("\n        " + _vm._s(_setup.$t('extension.confirm.back')) + "\n      ")])]), _vm._v(" "), _c(VSpacer)];
      },
      proxy: true
    }]),
    model: {
      value: _setup._show,
      callback: function callback($$v) {
        _setup._show = $$v;
      },
      expression: "_show"
    }
  }, [_c('div', {
    staticClass: "text-body-1 mb-6"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('extension.confirm.overwriteWowlink')) + "\n  ")]), _vm._v(" "), _c('OutlinedTable', {
    attrs: {
      "headers": [{
        text: '氏名',
        value: 'contact_name',
        multiLine: true
      }, {
        text: 'キャンペーン名',
        value: 'campaign.name',
        multiLine: true
      }, {
        text: 'タッチ名',
        value: 'touch_name',
        multiLine: true,
        empty: _setup.$t('none')
      }, {
        text: 'オーダー作成者',
        value: 'created_by.name'
      }, {
        text: 'オーダー作成日',
        value: 'created_at',
        transform: _vm.$date
      }, {
        text: '発行日',
        value: 'shipping_date',
        transform: _vm.$date
      }, {
        text: 'WOWリンククリック',
        value: 'touched_at',
        empty: _setup.$t('none')
      }],
      "items": _setup._orders,
      "item-key": "id",
      "disable-sort": "",
      "hide-default-footer": "",
      "fixed-first-column": _setup._orders.length <= 10
    },
    on: {
      "click:row": function clickRow(item) {
        return _vm.$open("/orders/".concat(item.id));
      }
    },
    scopedSlots: _vm._u([{
      key: "item.campaign.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.campaign ? _c('LinkText', {
          attrs: {
            "to": "/campaign/".concat(item.campaign.id),
            "text": item.campaign.name,
            "external": ""
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.touch_name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.line_items.length > 0 ? _c('LinkText', {
          attrs: {
            "to": "/touch/".concat(item.line_items[0].touch),
            "text": item.line_items[0].name,
            "external": ""
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.contact_name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.contact ? _c('LinkText', {
          attrs: {
            "to": item.contact.id ? "/contact/".concat(item.contact.id) : null,
            "text": _vm.$fullName(item.contact),
            "external": ""
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.touched_at",
      fn: function fn(_ref4) {
        var _item$line_items, _item$line_items$, _item$line_items$$lp;
        var item = _ref4.item;
        return [item.touched_at ? [_vm._v("\n        " + _vm._s(_vm.$date(item.touched_at)) + "\n        "), _c('br'), _vm._v("\n        " + _vm._s(_vm.$time(item.touched_at)) + "\n      ")] : (_item$line_items = item.line_items) !== null && _item$line_items !== void 0 && (_item$line_items$ = _item$line_items[0]) !== null && _item$line_items$ !== void 0 && (_item$line_items$$lp = _item$line_items$.lp) !== null && _item$line_items$$lp !== void 0 && _item$line_items$$lp.opened_at ? [_vm._v("\n        " + _vm._s(_vm.$date(item.line_items[0].lp.opened_at)) + "\n        "), _c('br'), _vm._v("\n        " + _vm._s(_vm.$time(item.line_items[0].lp.opened_at)) + "\n      ")] : _vm._e()];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };