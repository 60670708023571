import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "title": _vm.$t('confirmation')
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn() {
        return [_c(VSpacer), _vm._v(" "), _c('div', {
          staticClass: "d-flex flex-column mb-6"
        }, [_c('button', {
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.dialog = false;
              _vm.$nextTick(function () {
                _vm.$emit('click:positive');
              });
            }
          }
        }, [_vm._v("\n        " + _vm._s(_vm.$t('extension.confirm.continue')) + "\n      ")]), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-outline-primary mt-4",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.dialog = false;
              _vm.$nextTick(function () {
                _vm.$emit('click:negative');
              });
            }
          }
        }, [_vm._v("\n        " + _vm._s(_vm.$t('extension.confirm.back')) + "\n      ")])]), _vm._v(" "), _c(VSpacer)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.hasSyncError ? [_c('div', {
    staticClass: "d-flex align-center mb-2"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "size": "24",
      "color": "error"
    }
  }, [_vm._v("mdi-information")]), _vm._v(" "), _c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('extension.confirm.syncErrorContactWithCount', [_vm.hasSyncError])) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('extension.confirm.syncErrorContactDesc1')) + "\n    ")])] : _vm._e(), _vm._v(" "), _vm.hasSyncError && (_vm.hasOverwrited || _vm.hasSimilar) ? _c(VDivider, {
    staticClass: "my-6"
  }) : _vm._e(), _vm._v(" "), _vm.hasOverwrited ? [_c('div', {
    staticClass: "d-flex align-center mb-2"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "size": "24",
      "color": "warning"
    }
  }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("\n        " + _vm._s(typeof _vm.hasOverwrited === 'number' ? _vm.$t('extension.confirm.overwriteContactWithCount', [_vm.hasOverwrited]) : _vm.$t('extension.confirm.overwriteContact')) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('extension.confirm.overwriteContactDesc1', [_vm.crmName])) + "\n    ")])] : _vm._e(), _vm._v(" "), _vm.hasOverwrited && _vm.hasSimilar ? _c(VDivider, {
    staticClass: "my-6"
  }) : _vm._e(), _vm._v(" "), _vm.hasSimilar ? [_c('div', {
    staticClass: "d-flex align-center mb-2"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "size": "24",
      "color": "warning"
    }
  }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("\n        " + _vm._s(typeof _vm.hasSimilar === 'number' ? _vm.$t('extension.confirm.similarContactWithCount', [_vm.hasSimilar]) : _vm.$t('extension.confirm.similarContact')) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('extension.confirm.similarContactDesc1')) + "\n    ")])] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };