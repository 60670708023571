import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.dummy ? _c(VRow, _vm._l(2, function (n) {
    return _c(VCol, {
      key: n,
      attrs: {
        "cols": _setup.cols
      }
    }, [_c(_setup.ContactCard, {
      attrs: {
        "selectable": _vm.selectable,
        "contact": null,
        "dummy": ""
      }
    })], 1);
  }), 1) : _setup.contacts.length ? _c(VRow, _vm._l(_setup.contacts, function (contact) {
    return _c(VCol, {
      key: contact.id,
      attrs: {
        "cols": _setup.cols
      }
    }, [_c(_setup.ContactCard, {
      attrs: {
        "value": _setup.dataSelectedId === contact.id,
        "selectable": _vm.selectable,
        "contact": contact,
        "mandatory": _vm.mandatory
      },
      on: {
        "update:value": function updateValue($event) {
          return _setup.onChangeSelectContact(contact, $event);
        }
      }
    })], 1);
  }), 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };