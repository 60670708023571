import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    crmName: {
      type: String,
      required: true
    },
    hasSyncError: {
      type: Number,
      // 現状同期エラーの場合は件数指定しないケースはない
      default: 0
    },
    hasOverwrited: {
      type: [Boolean, Number],
      default: false
    },
    hasSimilar: {
      type: [Boolean, Number],
      default: false
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialog: {
      get: function get() {
        return this.show;
      },
      set: function set(value) {
        this.$emit('update:show', value);
      }
    }
  }
};