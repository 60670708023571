import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _setup$_similarContac, _setup$_similarContac2, _setup$_similarContac3;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.CustomDialog, {
    attrs: {
      "max-width": "800px",
      "title": _setup.$t('extension.similarDetail.title')
    },
    on: {
      "open": _setup.onOpen,
      "close": _setup.onClose
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn() {
        return [_c(VSpacer), _vm._v(" "), _c('div', {
          staticClass: "d-flex flex-column align-center"
        }, [_c('button', {
          staticClass: "tw-btn tw-btn-fill-primary mb-4",
          attrs: {
            "type": "button",
            "loading": _setup.isProgressing,
            "disabled": !_setup.selectedContactId
          },
          on: {
            "click": _setup.confirm
          }
        }, [_vm._v("\n        " + _vm._s(_setup.$t('extension.similarDetail.submit')) + "\n      ")]), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-outline-primary mb-4",
          attrs: {
            "type": "button",
            "loading": _setup.isProgressing
          },
          on: {
            "click": function click($event) {
              _setup._show = false;
            }
          }
        }, [_vm._v("\n        " + _vm._s(_setup.$t('extension.similarDetail.back')) + "\n      ")])]), _vm._v(" "), _c(VSpacer)];
      },
      proxy: true
    }]),
    model: {
      value: _setup._show,
      callback: function callback($$v) {
        _setup._show = $$v;
      },
      expression: "_show"
    }
  }, [_c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('extension.similarDetail.desc1', [_setup.crmName, (_setup$_similarContac = (_setup$_similarContac2 = _setup._similarContacts) === null || _setup$_similarContac2 === void 0 ? void 0 : _setup$_similarContac2.length) !== null && _setup$_similarContac !== void 0 ? _setup$_similarContac : '0'])) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 mt-4"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('extension.similarDetail.desc2')) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 mt-4"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('extension.similarDetail.desc3')) + "\n  ")]), _vm._v(" "), _c(_setup.ContactList, {
    staticClass: "mt-4",
    attrs: {
      "contacts": (_setup$_similarContac3 = _setup._similarContacts) !== null && _setup$_similarContac3 !== void 0 ? _setup$_similarContac3 : [],
      "selectable": "",
      "selected-id": _setup.selectedContactId,
      "dummy": _setup.isLoading
    },
    on: {
      "update:selectedId": function updateSelectedId($event) {
        _setup.selectedContactId = $event;
      },
      "update:selected-id": function updateSelectedId($event) {
        _setup.selectedContactId = $event;
      }
    }
  }), _vm._v(" "), _vm._v(" "), _c(_setup.ConfirmContactMigrationDialog, {
    ref: "confirmDialogRef",
    on: {
      "click:positive": _setup.submit
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };