import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm$productCustoms$te, _vm$productCustoms$te2, _vm$data$touchOrLineI2, _vm$data$touchOrLineI3, _vm$data$touchOrLineI4, _vm$data$touchOrLineI5, _vm$data$touchOrLineI6, _vm$data$touchOrLineI7;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing || _vm.loading.order,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-primary tw-mr-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1), _vm._v(" "), _c(VToolbarTitle, [_c('h5', [_vm._v("\n        " + _vm._s(_vm.data.draftOrderId != null ? _vm.$t('action.editingOrder') : _vm.$t('action.creationOrder')) + "\n      ")])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.data.draftOrderId ? [_c('div', {
    staticClass: "d-flex flex-column align-end"
  }, [_c('div', {
    staticClass: "d-flex text-caption"
  }, [_c('div', {
    staticClass: "secondary-text--text mr-2"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('contacts.send.draft.lastUpdatedAt')) + "\n          ")]), _vm._v("\n          " + _vm._s(_vm.data.draftOrderUpdatedAt) + "\n        ")]), _vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _vm.$t('action.checkDraft')
    },
    on: {
      "click": _vm.openDraftSelectDialog
    }
  })], 1), _vm._v(" "), _c(VDivider, {
    staticClass: "ml-3 align-self-center",
    staticStyle: {
      "height": "32px",
      "min-height": "32px"
    },
    attrs: {
      "vertical": ""
    }
  })] : _vm._e(), _vm._v(" "), _c('FloatingTooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('button', _vm._g({
          staticClass: "tw-btn tw-btn-icon-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchContact();
            }
          }
        }, on), [_c('mdi-reload', {
          staticClass: "tw-size-6"
        })], 1)];
      }
    }])
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t('action.reload')))])]), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('logout'))
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/logout'
        });
      }
    }
  })], 2), _vm._v(" "), _c('ContentArea', {
    attrs: {
      "set": _vm.labelNumber = 1
    }
  }, [_c('SectionSubTitle', {
    ref: "destination",
    staticClass: "mb-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('destination')) + "\n    ")]), _vm._v(" "), _c('ShippingInfoArea', {
    class: {
      disabled: !_vm.step0
    },
    attrs: {
      "dummy": _vm.loading.contact,
      "contact": _vm.data.contact,
      "salutation": _vm.data.salutation,
      "editable": ""
    },
    on: {
      "update:salutation": function updateSalutation($event) {
        return _vm.$set(_vm.data, "salutation", $event);
      },
      "click:similar": function clickSimilar($event) {
        _vm.dialog.similar = true;
      },
      "click:overwrite": function clickOverwrite($event) {
        _vm.dialog.overwrite = true;
      }
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.data.draftOrderId == null ? _vm.labelNumber++ : null,
      "color": _vm.data.draftOrderId == null && _vm.step1 ? 'primary' : null
    }
  }, [_vm._v("\n      " + _vm._s(_vm.data.draftOrderId ? _vm.$t('delivery') : _vm.$t('action.selectionTouch')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _vm.data.draftOrderId ? _c('VerticalTable', {
    staticClass: "mt-4",
    attrs: {
      "headers": [{
        text: _vm.$t('touch'),
        value: 'touch'
      }],
      "item": _vm.data.touchOrLineItem
    },
    scopedSlots: _vm._u([{
      key: "item.touch",
      fn: function fn() {
        var _vm$data$touchOrLineI;
        return [_c('TouchSummary', {
          staticClass: "py-2",
          attrs: {
            "item": (_vm$data$touchOrLineI = _vm.data.touchOrLineItem) !== null && _vm$data$touchOrLineI !== void 0 ? _vm$data$touchOrLineI : {
              id: '',
              name: ''
            },
            "dummy": !_vm.data.touchOrLineItem
          }
        })];
      },
      proxy: true
    }], null, false, 1520924783)
  }) : _c('TouchItems', {
    attrs: {
      "selectable": "",
      "type": _vm.type,
      "filters": _vm.filterOptions,
      "options": _vm.tableOptions,
      "value": _vm.data.touchOrLineItem
    },
    on: {
      "update:type": function updateType($event) {
        _vm.type = $event;
      },
      "update:filters": function updateFilters($event) {
        _vm.filterOptions = $event;
      },
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "update:value": _vm.onClickItem
    }
  })], 1), _vm._v(" "), !_vm.isDigitalGift ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.shippingAddress,
      "initial-value": _vm.initialFormData.shippingAddress
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "shippingAddress", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.data.draftOrderId == null ? _vm.labelNumber++ : null,
      "color": _vm.data.draftOrderId == null && _vm.step2 ? 'primary' : null
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('action.selectionDestinationAddress')) + "\n        ")]), _vm._v(" "), _vm.selectableAddresses.length ? _c('Input', {
    attrs: {
      "required": "",
      "type": "select",
      "items": _vm.selectableAddresses,
      "item-text": "label",
      "item-value": "id",
      "return-object": "",
      "placeholder": _vm.$t('placeholder.shippingAddress')
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('AddressLabel', {
          attrs: {
            "detail": "",
            "alert-color": "warning",
            "address": item
          }
        })];
      }
    }, {
      key: "item",
      fn: function fn(_ref3) {
        var _vm$data$shippingAddr;
        var item = _ref3.item,
          attrs = _ref3.attrs;
        return [_c('AddressLabel', {
          attrs: {
            "detail": "",
            "address": item,
            "active-class": attrs.activeClass,
            "selected": ((_vm$data$shippingAddr = _vm.data.shippingAddress) === null || _vm$data$shippingAddr === void 0 ? void 0 : _vm$data$shippingAddr.id) === item.id
          }
        })];
      }
    }], null, false, 3613307797),
    model: {
      value: _vm.data.shippingAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "shippingAddress", $$v);
      },
      expression: "data.shippingAddress"
    }
  }) : _c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "size": "24",
      "color": "warning"
    }
  }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
    staticClass: "text-body-1 warning--text"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('warning.noAddresses')) + "\n          ")])], 1)], 1), _vm._v(" "), _c('AddressAlert', {
    staticClass: "mt-2",
    attrs: {
      "address": _vm.data.shippingAddress,
      "select-mode": "",
      "empty": !_vm.selectableAddresses.length
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": {
        inhouseDate: _vm.data.inhouseDate,
        shippingMethod: _vm.data.shippingMethod
      },
      "initial-value": {
        inhouseDate: _vm.initialFormData.inhouseDate,
        shippingMethod: _vm.initialFormData.shippingMethod
      },
      "disabled": _vm.isEmpty(_vm.initialFormData)
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.data.inhouseDate = $event.inhouseDate;
        _vm.data.shippingMethod = $event.shippingMethod;
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step3 ? 'primary' : null
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('shipping.selectDesiredArrivalDate')) + "\n          "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('shipping.desiredArrivalDatePrecautions'),
      "icon": "mdi-alert"
    },
    on: {
      "click": _vm.$openDesiredArrivalDatePrecautions
    }
  })], 1), _vm._v(" "), _c('ShippingDateSelect', {
    attrs: {
      "shipping-date": _vm.data.inhouseDate,
      "shipping-method": _vm.data.shippingMethod
    },
    on: {
      "update:shippingDate": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shipping-date": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shippingMethod": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      },
      "update:shipping-method": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      },
      "update:error": function updateError($event) {
        _vm.hasDateError = $event;
      }
    }
  })], 1)] : _vm._e(), _vm._v(" "), ((_vm$productCustoms$te = _vm.productCustoms.texts) === null || _vm$productCustoms$te === void 0 ? void 0 : _vm$productCustoms$te.length) > 0 || _vm.productCustoms.url ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.customTexts,
      "initial-value": _vm.initialFormData.customTexts
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "customTexts", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    ref: "svg",
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step4 ? 'primary' : null
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('shipping.customizePrintedText')) + "\n          ")]), _vm._v(" "), ((_vm$productCustoms$te2 = _vm.productCustoms.texts) === null || _vm$productCustoms$te2 === void 0 ? void 0 : _vm$productCustoms$te2.length) > 0 ? _c('TouchTextViewer', {
    key: "svg-".concat((_vm$data$touchOrLineI2 = _vm.data.touchOrLineItem) === null || _vm$data$touchOrLineI2 === void 0 ? void 0 : _vm$data$touchOrLineI2.id),
    attrs: {
      "svg": _vm.data.svg,
      "shipping-targets": [{
        contact: _vm.complementedContact,
        shippingAddress: _vm.data.shippingAddress
      }],
      "qr-url": _vm.data.url,
      "logo": _vm.company.image,
      "customs-texts": _vm.productCustoms.texts,
      "edit": "",
      "required": "",
      "check-placeholder": ""
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasTextError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.customTexts,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "customTexts", $$v);
      },
      expression: "data.customTexts"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.url,
      "initial-value": _vm.initialFormData.url
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "url", $event);
      }
    }
  }, [_vm.productCustoms.url ? _c('Input', {
    key: "url-".concat((_vm$data$touchOrLineI3 = _vm.data.touchOrLineItem) === null || _vm$data$touchOrLineI3 === void 0 ? void 0 : _vm$data$touchOrLineI3.id),
    staticClass: "mt-6",
    attrs: {
      "label": _vm.$t('qrUrl'),
      "required": "",
      "rules": [function (v) {
        return _vm.$isValidUrl(v) || _vm.$t('error.url');
      }]
    },
    model: {
      value: _vm.data.url,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "url", $$v);
      },
      expression: "data.url"
    }
  }) : _vm._e()], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.hasGiftProduct ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.lp,
      "initial-value": _vm.initialFormData.lp,
      "disabled": _vm.isEmpty(_vm.initialFormData.lp),
      "keys": ['sender', 'message', 'cta_text', 'cta_link']
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "lp", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    ref: "gift",
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++,
      "color": _vm.step5 ? 'primary' : null
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('shipping.customizeGiftPage')) + "\n        ")]), _vm._v(" "), _c('GiftPageEditor', {
    key: "gift-".concat((_vm$data$touchOrLineI4 = _vm.data.touchOrLineItem) === null || _vm$data$touchOrLineI4 === void 0 ? void 0 : _vm$data$touchOrLineI4.id),
    attrs: {
      "edit": "",
      "required": "",
      "check-placeholder": "",
      "logo": _vm.company.image,
      "shipping-targets": [{
        contact: _vm.complementedContact,
        shippingAddress: _vm.data.shippingAddress
      }],
      "product-title": (_vm$data$touchOrLineI5 = _vm.data.touchOrLineItem.shopify_product.display_gift_name) !== null && _vm$data$touchOrLineI5 !== void 0 ? _vm$data$touchOrLineI5 : _vm.data.touchOrLineItem.shopify_product.product_title,
      "product-image": (_vm$data$touchOrLineI6 = _vm.data.touchOrLineItem.shopify_product.display_gift_image) !== null && _vm$data$touchOrLineI6 !== void 0 ? _vm$data$touchOrLineI6 : (_vm$data$touchOrLineI7 = _vm.data.touchOrLineItem.shopify_product.images) === null || _vm$data$touchOrLineI7 === void 0 ? void 0 : _vm$data$touchOrLineI7[0]
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasGiftError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.lp,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "lp", $$v);
      },
      expression: "data.lp"
    }
  })], 1)] : _vm._e()], 2), _vm._v(" "), _vm.isDigitalGift && _vm.crmName === 'Salesforce' && _vm.data.contact ? _c('ContentArea', [_c('SectionSubTitle', {
    staticClass: "mb-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('shipping.selectWriteWowlink')) + "\n      "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('settings.integration.aboutSetting'),
      "icon": "mdi-help-circle"
    },
    on: {
      "click": _vm.$openAboutSalesforceWriteWowlink
    }
  })], 1), _vm._v(" "), _c('WriteWowlinkSetting', {
    attrs: {
      "value": _vm.data.writeWowlink,
      "crm-types": [_vm.data.contact.crm],
      "status": _vm.data.writeWowlinkSettingStatus
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "writeWowlink", $event);
      },
      "update:status": function updateStatus($event) {
        return _vm.$set(_vm.data, "writeWowlinkSettingStatus", $event);
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('div', {
    staticClass: "flex-equality d-flex justify-end align-center"
  }, [_c('TextButton', {
    staticClass: "mr-4",
    attrs: {
      "value": _vm.$t('action.doSaveDraft'),
      "disabled": !_vm.isSaveDraftEnable
    },
    on: {
      "click": _vm.saveDraft
    }
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doCheckOrder'))
    },
    on: {
      "click": _vm.confirmSubmit
    }
  }), _vm._v(" "), _c(VSpacer, {
    staticClass: "flex-equality"
  })], 1), _vm._v(" "), _c('TextEditorContextMenu', {
    ref: "textmenu"
  }), _vm._v(" "), _vm.data.contact ? _c('OverwriteDetailDialog', {
    attrs: {
      "show": _vm.dialog.overwrite,
      "contact": _vm.data.contact,
      "crm-name": _vm.crmName,
      "before-contact": _vm.beforeContact,
      "loading": _vm.loading.contact
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "overwrite", $event);
      },
      "click:reload": _vm.fetchContact
    }
  }) : _vm._e(), _vm._v(" "), _vm.data.contact ? _c('SimilarDetailDialog', {
    attrs: {
      "show": _vm.dialog.similar,
      "contact": _vm.data.contact,
      "similar-contacts": _vm.similarContacts
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "similar", $event);
      },
      "update:contact": function updateContact($event) {
        _vm.data.contact = $event;
        _vm.similarContacts = [];
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.data.contact ? _c('SyncStatusConfirmDialog', {
    attrs: {
      "show": _vm.dialog.syncConfirm,
      "crm-name": _vm.crmName,
      "has-similar": _vm.data.contact.instance_status === 'has_similar',
      "has-overwrited": _vm.data.contact.sync_status === 'unsynchronized'
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "syncConfirm", $event);
      },
      "click:positive": _vm.submit,
      "click:negative": function clickNegative($event) {
        return _vm.$refs.destination.$el.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('OverwriteWowlinkConfirmDialog', {
    ref: "overwriteWowlinkConfirmDialog"
  }), _vm._v(" "), _c('SelectDraftOrderDialog', {
    ref: "selectDraftOrderDialog",
    on: {
      "update:selectedOrderId": function updateSelectedOrderId($event) {
        return _vm.loadOrder($event);
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };