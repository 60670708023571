import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "p-relative"
  }, [_setup.loading ? _c('Loading') : _vm._e(), _vm._v(" "), _setup.hasFetchError ? _c('AlertArea', {
    staticClass: "mb-4",
    attrs: {
      "type": "warning",
      "no-margin": "",
      "message": _setup.$t('error.fetch')
    }
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-text-primary",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_setup.$t('action.reload'))
    },
    on: {
      "click": function click($event) {
        return _setup.fetch(true);
      }
    }
  })]) : _vm._e(), _vm._v(" "), !_vm.readonly ? _c(VCheckbox, {
    attrs: {
      "disabled": _setup.disabled,
      "hide-details": "",
      "label": _setup.$t('extension.writeWowlink.writeTo', [_setup.crmNames.join(_setup.$t('&')) || 'CRM'])
    },
    model: {
      value: _setup.checked,
      callback: function callback($$v) {
        _setup.checked = $$v;
      },
      expression: "checked"
    }
  }) : _vm._e(), _vm._v(" "), Object.values(_setup.statusInfo).filter(function (i) {
    return i != null;
  }).length ? _c('div', {
    staticClass: "mt-4",
    style: {
      opacity: !_setup.checked && !_setup.disabled ? 0.4 : undefined
    }
  }, [_c('OutlinedTable', {
    staticClass: "disable-hover",
    attrs: {
      "headers": [{
        text: _setup.$t('extension.writeWowlink.writeDestination'),
        value: 'title'
      }, {
        text: _setup.$t('extension.writeWowlink.writeStatus'),
        value: 'status'
      }, {
        text: _setup.$t('extension.writeWowlink.fieldLabel'),
        value: 'label'
      }],
      "items": Object.values(_setup.statusInfo).filter(function (i) {
        return i != null;
      }),
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [(_vm.color = item.status === 'valid' ? 'primary' : item.status === 'invalid' || _vm.readonly ? 'secondary-text' : 'error') ? void 0 : _vm._e(), _vm._v(" "), _c(VIcon, {
          attrs: {
            "small": "",
            "color": _vm.color
          }
        }, [_vm._v("\n            " + _vm._s(item.status === 'valid' ? 'mdi-check-circle' : item.status === 'invalid' || _vm.readonly ? null : 'mdi-alert-outline') + "\n          ")]), _vm._v(" "), _c('span', {
          class: "".concat(_vm.color, "--text ml-1 text-body-1")
        }, [_vm._v("\n            " + _vm._s(item.status === 'valid' ? _setup.$t('valid') : item.status === 'invalid' ? _setup.$t('invalid') : _vm.readonly ? _setup.$t('because', [_setup.$t('invalid'), _setup.$t('settingError')]) : _setup.$t('settingError')) + "\n          ")])], 2)];
      }
    }, {
      key: "item.label",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.status === 'error' ? _c('span', {
          staticClass: "text-caption disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_setup.$t('extension.writeWowlink.fieldError')) + "\n        ")]) : item.field != null ? [_vm._v("\n          " + _vm._s("".concat(item.field.label, " (").concat(item.field.name, ")")) + "\n        ")] : _c('span', {
          staticClass: "text-caption disabled-text--text"
        }, [_vm._v("\n          " + _vm._s(_setup.$t('notSet')) + "\n        ")])];
      }
    }], null, false, 3196817426)
  }), _vm._v(" "), !_vm.readonly ? _c('div', {
    staticClass: "text-subtitle-2 mt-4 d-flex flex-wrap align-baseline"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('extension.writeWowlink.notice1')) + "\n      "), _c('LinkText', {
    staticClass: "mx-1",
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "to": "/settings/integration/".concat(_setup.crmNames.length === 1 ? _setup.crmNames[0].toLowerCase() : ''),
      "text": _setup.$t('sendwowSetting'),
      "external": ""
    }
  }), _vm._v("\n      " + _vm._s(_setup.$t('extension.writeWowlink.notice2')) + "\n      "), _c('TextButton', {
    staticClass: "mx-1",
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "value": _setup.$t('action.reload')
    },
    on: {
      "click": function click($event) {
        return _setup.fetch(true);
      }
    }
  }), _vm._v("\n      " + _vm._s(_setup.$t('extension.writeWowlink.notice3')) + "\n    ")], 1) : _vm._e()], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };